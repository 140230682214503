import React from "react";
import ThankYouPage from "../../components/thank-you/thank-you";
import './thank-you.scss';


export default ({location}) => {

    return (<ThankYouPage location={location}>
      <section id="main" className="main-title-section main-title-section-dark">
        <div className="page-content container">
        <div className="row items-center">
        <div className="col-12 col-md-10">
          <h1>Thank you!</h1>
          <h2 className="subtitle">We’ll be in touch soon.</h2>
          </div>
            <div className="page_icon_wrapper hidden lg:block col-2">
             <div className="page_icon"><img src="https://amazeeio.cdn.prismic.io/amazeeio/cdd4fb59-26a5-4429-bf15-90cd108b0951_Thankyou-landing.svg" alt="Thank you" /></div>
            </div>
          </div>
        </div>
      </section>
      <div className="page-content container lower-content">
        <h2>Want to learn more about WebOps?</h2>
        <strong>Visit our blog:</strong>
        <ul>
          <li><a href="https://www.amazee.io/blog/post/what-is-webops">What Is WebOps? Here’s how it solves your hosting problems</a></li>
          <li><a href="https://www.amazee.io/blog/post/5-key-reasons-more-businesses-are-relying-on-webops">5 key reasons more businesses are relying on WebOps in 2022</a></li>
          <li><a href="https://www.amazee.io/blog/post/what-you-need-to-know-about-open-source-software-and-webops">Here’s what you need to know about open source software and WebOps</a></li>
        </ul>
      </div>
      </ThankYouPage>
    );
}
